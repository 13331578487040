
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '../../../components/TitleReturn.vue';
import FormBlock from './_form.vue';
import IconFont from '@/components/icons/IconFont.vue';
import Loading from '@/components/Loading.vue';

import CustomersHelpersModule from '@/store/customers/helpers';
import CustomersModule from '@/store/customers';
import CustomerEntityModule from '@/store/customers/entity';

@Component({
  components: {
    IconFont,
    TitleReturn,
    FormBlock,
    Loading,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
  },
})
export default class CustomerHelpersAdd extends Form {
  customerId = '';
  isLoading = true;

  get titlePage(): string {
    return CustomersHelpersModule.pageSettings.titleAdd;
  }

  get clientName(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers_helpers', params: { customerId: this.customerId } });
  }

  async mounted(): Promise<void> {
    await CustomerEntityModule.initEdit(this.customerId);

    this.isLoading = false;
  }
}
